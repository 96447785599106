import { create } from '@stylexjs/stylex'
import { spacing } from '../../styles/token.stylex'

export const errorPageStyles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    gap: '1rem',
  },
  linkContainer: {
    paddingTop: spacing.normal,
  },
})
