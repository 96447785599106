import type { SVGProps } from 'react'

export const Bell: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.16406 14.5858L5.55357 14.1963C5.67924 14.0706 5.75 13.9002 5.75 13.7224V10C5.75 6.13401 8.88401 3.00001 12.75 3C16.616 2.99999 19.75 6.134 19.75 10V13.7224C19.75 13.9001 19.8206 14.0706 19.9463 14.1963L20.3358 14.5858C20.4551 14.7051 20.5149 14.7648 20.5624 14.831C20.6523 14.9561 20.7119 15.1003 20.7368 15.2523C20.75 15.3328 20.7502 15.4171 20.7502 15.5858C20.7502 15.9714 20.7502 16.1642 20.6978 16.3197C20.598 16.6155 20.3656 16.8477 20.0698 16.9475C19.9143 17 19.7212 17 19.3356 17H6.16406C5.77852 17 5.58568 17 5.43018 16.9475C5.13431 16.8477 4.90225 16.6155 4.80245 16.3196C4.75 16.1641 4.75 15.9714 4.75 15.5858C4.75 15.4171 4.75 15.3328 4.7632 15.2523C4.78815 15.1003 4.84766 14.9561 4.9375 14.831C4.98504 14.7648 5.04476 14.7051 5.16406 14.5858ZM15.75 17V18C15.75 19.6569 14.4069 21 12.75 21C11.0931 21 9.75 19.6569 9.75 18V17H15.75Z"
      stroke={stroke}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
