import { createStitches } from '@stitches/react'

const MEDIA = {
  phone: '(min-width: 0)',
  tablet: '(min-width: 600px)',
  desktop: '(min-width: 1200px)',
  onlyPhone: '(min-width: 0) and (max-width: 599px)',
  onlyTablet: '(min-width: 600px) and (max-width: 1199px)',
  onlyDesktop: '(min-width: 1200px)',
}

export type MediaConfig<T> =
  | {
      [KMedia in `@${'initial' | keyof typeof MEDIA}`]?: T
    }
  | T

export const { styled } = createStitches({
  media: MEDIA,
})

export const Container = styled('div', {
  width: '100%',
  margin: 'auto',
})
