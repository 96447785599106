import type { SVGProps } from 'react'

type LogoSizeVariant = 'default' | 'larger'

type LogoProps = SVGProps<SVGElement> & {
  variant?: LogoSizeVariant
}

const sizes: Record<LogoSizeVariant, { width: string; height: string }> = {
  default: { width: '151px', height: '36px' },
  larger: { width: '302px', height: '72px' },
}

export const Logo: React.FC<LogoProps> = ({ variant = 'default' }) => {
  const { width, height } = sizes[variant]

  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1034.51 204.45"
      width={width}
      height={height}
      fill="#FF1D49"
    >
      <g id="_レイヤー_1" data-name="レイヤー 1">
        <path d="m226.81,24.12v62.09l-33.47,26.64,33.47,27.08v64.51H0V24.12h226.81Zm-113.41,41.39v26.42h45.37v-26.42h-45.37Zm0,71.53v26.42h45.37v-26.42h-45.37Z" />
        <polygon points="233.64 24.12 233.64 204.45 410.88 204.45 410.88 142.36 378.96 142.36 378.96 128.27 410.88 128.27 410.88 93.26 379.4 93.26 379.4 78.73 410.88 77.85 410.88 24.12 233.64 24.12" />
        <polygon points="417.82 24.12 417.82 204.45 595.06 204.45 595.06 142.36 563.14 142.36 563.14 128.27 595.06 128.27 595.06 93.26 563.58 93.26 563.58 78.73 595.06 77.85 595.06 24.12 417.82 24.12" />
        <g>
          <path d="m645.62,176.53l11.54-1.76c.49,2.24,1.49,3.94,2.99,5.11s3.6,1.74,6.3,1.74c2.97,0,5.21-.55,6.71-1.64,1.01-.77,1.51-1.79,1.51-3.08,0-.88-.27-1.6-.82-2.17-.57-.55-1.86-1.05-3.85-1.52-9.27-2.05-15.15-3.92-17.63-5.62-3.44-2.35-5.15-5.62-5.15-9.8,0-3.77,1.49-6.95,4.46-9.52s7.58-3.86,13.83-3.86,10.36.97,13.26,2.91c2.89,1.94,4.88,4.81,5.97,8.61l-10.84,2.01c-.46-1.7-1.34-2.99-2.64-3.9s-3.14-1.35-5.54-1.35c-3.03,0-5.2.42-6.5,1.27-.87.6-1.31,1.38-1.31,2.34,0,.82.38,1.52,1.15,2.09,1.04.77,4.62,1.85,10.74,3.24s10.4,3.1,12.83,5.13c2.4,2.05,3.6,4.91,3.6,8.57,0,3.99-1.66,7.42-4.99,10.29-3.33,2.87-8.25,4.31-14.77,4.31-5.92,0-10.6-1.2-14.05-3.61s-5.71-5.67-6.77-9.8Z" />
          <path d="m740.49,145.4v9.19h-7.86v17.55c0,3.55.08,5.63.22,6.21.15.59.49,1.07,1.02,1.46.53.38,1.18.57,1.94.57,1.06,0,2.6-.37,4.62-1.11l.98,8.94c-2.67,1.15-5.7,1.72-9.08,1.72-2.07,0-3.94-.35-5.61-1.05s-2.88-1.6-3.66-2.71-1.32-2.6-1.62-4.49c-.25-1.34-.37-4.05-.37-8.12v-18.99h-5.28v-9.19h5.28v-8.65l11.54-6.73v15.38h7.86Z" />
          <path d="m783.77,188.96h-11.5v-43.56h10.68v6.19c1.83-2.93,3.47-4.85,4.93-5.78s3.12-1.39,4.97-1.39c2.62,0,5.14.72,7.57,2.17l-3.56,10.05c-1.94-1.26-3.74-1.89-5.4-1.89s-2.97.44-4.09,1.33-2,2.5-2.64,4.82-.96,7.19-.96,14.6v13.45Z" />
          <path d="m854.83,175.1l11.46,1.93c-1.47,4.21-3.8,7.42-6.98,9.62s-7.15,3.3-11.93,3.3c-7.55,0-13.15-2.47-16.77-7.42-2.86-3.96-4.3-8.97-4.3-15.01,0-7.22,1.88-12.87,5.65-16.96,3.76-4.09,8.52-6.13,14.28-6.13,6.46,0,11.56,2.14,15.3,6.42,3.74,4.28,5.52,10.83,5.36,19.67h-28.8c.08,3.42,1.01,6.08,2.78,7.98,1.77,1.9,3.98,2.85,6.63,2.85,1.8,0,3.31-.49,4.54-1.48s2.15-2.57,2.78-4.76Zm.65-11.65c-.08-3.34-.94-5.87-2.58-7.61s-3.63-2.6-5.97-2.6c-2.51,0-4.58.92-6.22,2.75-1.64,1.83-2.44,4.32-2.41,7.46h17.18Z" />
          <path d="m909.16,158.69l-10.43-1.89c1.17-4.21,3.19-7.33,6.05-9.35,2.86-2.02,7.12-3.04,12.76-3.04,5.13,0,8.95.61,11.46,1.83,2.51,1.22,4.27,2.76,5.3,4.63,1.02,1.87,1.53,5.31,1.53,10.32l-.12,13.45c0,3.83.18,6.65.55,8.47s1.06,3.77,2.07,5.84h-11.37c-.3-.77-.67-1.9-1.1-3.4-.19-.68-.33-1.13-.41-1.35-1.96,1.91-4.06,3.35-6.3,4.31s-4.62,1.44-7.16,1.44c-4.47,0-8-1.22-10.58-3.65-2.58-2.43-3.87-5.51-3.87-9.23,0-2.46.59-4.66,1.76-6.58,1.17-1.93,2.82-3.4,4.93-4.43,2.11-1.03,5.16-1.92,9.14-2.69,5.37-1.01,9.1-1.96,11.17-2.83v-1.15c0-2.21-.55-3.79-1.64-4.74s-3.15-1.42-6.18-1.42c-2.04,0-3.64.4-4.79,1.21s-2.07,2.22-2.78,4.25Zm15.38,9.35c-1.47.49-3.8,1.08-7,1.76-3.19.68-5.28,1.35-6.26,2.01-1.5,1.07-2.25,2.42-2.25,4.06s.6,3.01,1.8,4.18c1.2,1.18,2.73,1.76,4.58,1.76,2.07,0,4.05-.68,5.93-2.05,1.39-1.04,2.3-2.31,2.74-3.81.3-.98.45-2.86.45-5.62v-2.3Z" />
          <path d="m970.61,145.4h10.6v5.95c3.79-4.62,8.31-6.93,13.54-6.93,2.78,0,5.2.57,7.24,1.72s3.72,2.88,5.03,5.21c1.91-2.32,3.97-4.06,6.18-5.21s4.57-1.72,7.08-1.72c3.19,0,5.89.65,8.1,1.95,2.21,1.3,3.86,3.21,4.95,5.72.79,1.86,1.19,4.87,1.19,9.02v27.85h-11.5v-24.9c0-4.32-.4-7.11-1.19-8.37-1.06-1.64-2.7-2.46-4.91-2.46-1.61,0-3.12.49-4.54,1.48s-2.44,2.43-3.07,4.33c-.63,1.9-.94,4.9-.94,9v20.92h-11.5v-23.87c0-4.24-.2-6.97-.61-8.2s-1.04-2.15-1.9-2.75-2.03-.9-3.5-.9c-1.77,0-3.37.48-4.79,1.44s-2.43,2.34-3.05,4.14c-.61,1.8-.92,4.8-.92,8.98v21.16h-11.5v-43.56Z" />
        </g>
        <path d="m633.14,0c3.38,0,6.62.85,9.72,2.55,3.1,1.7,5.54,4.12,7.32,7.27,1.78,3.15,2.66,6.44,2.66,9.89s-.87,6.7-2.61,9.81c-1.74,3.11-4.17,5.54-7.28,7.28-3.11,1.74-6.38,2.61-9.81,2.61s-6.7-.87-9.82-2.61-5.54-4.17-7.28-7.28c-1.74-3.11-2.61-6.38-2.61-9.81s.89-6.75,2.66-9.89c1.77-3.15,4.21-5.57,7.32-7.27,3.1-1.7,6.35-2.55,9.73-2.55Zm0,3.89c-2.71,0-5.31.68-7.81,2.03-2.5,1.35-4.46,3.3-5.88,5.83-1.42,2.53-2.14,5.18-2.14,7.96s.7,5.37,2.1,7.87c1.4,2.5,3.35,4.46,5.85,5.85,2.5,1.4,5.13,2.1,7.87,2.1s5.37-.7,7.87-2.1c2.5-1.4,4.46-3.35,5.85-5.85,1.4-2.5,2.1-5.13,2.1-7.87s-.71-5.41-2.14-7.95c-1.42-2.54-3.38-4.49-5.88-5.84-2.5-1.35-5.1-2.03-7.81-2.03Zm4.79,18.76l4.14,1.39c-.65,2.16-1.73,3.82-3.24,4.97-1.51,1.15-3.31,1.72-5.4,1.72-2.93,0-5.33-.96-7.18-2.88s-2.78-4.57-2.78-7.95c0-2.21.41-4.18,1.23-5.92.82-1.73,2.01-3.03,3.56-3.88,1.55-.86,3.29-1.29,5.21-1.29,2.11,0,3.87.5,5.29,1.5s2.52,2.59,3.31,4.75l-4.17.98c-.48-1.25-1.1-2.16-1.87-2.73-.76-.57-1.67-.85-2.71-.85-1.56,0-2.84.59-3.83,1.76-1,1.17-1.49,3.01-1.49,5.52s.49,4.35,1.47,5.53,2.15,1.77,3.52,1.77c1.15,0,2.14-.34,2.99-1.02.84-.68,1.49-1.8,1.96-3.38Z" />
      </g>
    </svg>
  )
}
