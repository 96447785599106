import type { SVGProps } from 'react'

export const House: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.87316 1.44191C9.56087 1.19902 9.40473 1.07758 9.23231 1.03089C9.08018 0.989702 8.91983 0.989702 8.76769 1.03089C8.59527 1.07758 8.43913 1.19902 8.12684 1.44191L2.09812 6.13092C1.69513 6.44436 1.49363 6.60108 1.34847 6.79734C1.21988 6.9712 1.12409 7.16706 1.0658 7.37529C1 7.61038 1 7.86565 1 8.37618V14.8072C1 15.8029 1 16.3007 1.19377 16.681C1.36421 17.0155 1.63617 17.2875 1.97068 17.4579C2.35097 17.6517 2.8488 17.6517 3.84444 17.6517H5.62222C5.87113 17.6517 5.99559 17.6517 6.09066 17.6032C6.17429 17.5606 6.24228 17.4926 6.28489 17.409C6.33333 17.3139 6.33333 17.1895 6.33333 16.9406V11.0739C6.33333 10.5761 6.33333 10.3272 6.43022 10.137C6.51544 9.96977 6.65142 9.83378 6.81868 9.74856C7.00882 9.65168 7.25773 9.65168 7.75556 9.65168H10.2444C10.7423 9.65168 10.9912 9.65168 11.1813 9.74856C11.3486 9.83378 11.4846 9.96977 11.5698 10.137C11.6667 10.3272 11.6667 10.5761 11.6667 11.0739V16.9406C11.6667 17.1895 11.6667 17.3139 11.7151 17.409C11.7577 17.4926 11.8257 17.5606 11.9093 17.6032C12.0044 17.6517 12.1289 17.6517 12.3778 17.6517H14.1556C15.1512 17.6517 15.649 17.6517 16.0293 17.4579C16.3638 17.2875 16.6358 17.0155 16.8062 16.681C17 16.3007 17 15.8029 17 14.8072V8.37618C17 7.86565 17 7.61038 16.9342 7.37529C16.8759 7.16706 16.7801 6.9712 16.6515 6.79734C16.5064 6.60108 16.3049 6.44436 15.9019 6.13092L9.87316 1.44191Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
