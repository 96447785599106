import { props, create, keyframes } from '@stylexjs/stylex'
import { SVGProps } from 'react'
import { color } from '../../styles/token.stylex'

const spinAnimation = keyframes({
  from: { transform: 'rotate(0deg)' },
  to: { transform: 'rotate(360deg)' },
})

const styles = create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  fullScreenContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: color.codGray,
    zIndex: 1000,
  },
  svg: {
    animation: `${spinAnimation} 1s linear infinite`,
    color: color.gold,
  },
  circle: {
    opacity: 0.25,
  },
  path: {
    opacity: 0.75,
  },
})

type LoadingScreenProps = {
  fullScreen?: boolean
} & SVGProps<SVGElement>

export const LoadingScreen: React.FC<LoadingScreenProps> = ({
  height = '2.5rem',
  width = '2.5rem',
  stroke = 'currentColor',
  fullScreen = false,
}) => {
  return (
    <div {...props(styles.container, fullScreen && styles.fullScreenContainer)}>
      <svg
        {...props(styles.svg)}
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          {...props(styles.circle)}
          cx="12"
          cy="12"
          r="10"
          stroke={stroke}
          strokeWidth="4"
        />
        <path
          {...props(styles.path)}
          fill={stroke}
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
    </div>
  )
}
