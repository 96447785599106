import type { SVGProps } from 'react'

export const VerifiedSecondary: React.FC<SVGProps<SVGElement>> = ({
  stroke = '#2F2F2F',
  fill = '#FFD700',
  height = '1.125rem',
  width = '1.125rem',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_495_578)">
      <path
        d="M7.35774 1.73818C8.23386 0.759526 9.76609 0.759527 10.6422 1.73818L10.8695 1.99207C11.316 2.49083 11.965 2.75965 12.6334 2.7227L12.9736 2.70389C14.2851 2.63138 15.3686 3.71483 15.2961 5.02635L15.2773 5.36659C15.2403 6.035 15.5091 6.68398 16.0079 7.13049L16.2618 7.35777C17.2405 8.23389 17.2404 9.76612 16.2618 10.6422L16.0079 10.8695C15.5091 11.316 15.2403 11.965 15.2773 12.6334L15.2961 12.9737C15.3686 14.2852 14.2851 15.3686 12.9736 15.2961L12.6334 15.2773C11.965 15.2404 11.316 15.5092 10.8695 16.0079L10.6422 16.2618C9.76609 17.2405 8.23386 17.2405 7.35774 16.2618L7.13046 16.0079C6.68395 15.5092 6.03497 15.2404 5.36656 15.2773L5.02632 15.2961C3.7148 15.3686 2.63135 14.2852 2.70386 12.9737L2.72267 12.6334C2.75962 11.965 2.4908 11.316 1.99203 10.8695L1.73815 10.6422C0.759496 9.76612 0.759496 8.23389 1.73815 7.35777L1.99204 7.13049C2.4908 6.68398 2.75962 6.035 2.72267 5.36659L2.70386 5.02635C2.63135 3.71483 3.7148 2.63138 5.02632 2.70389L5.36656 2.7227C6.03497 2.75965 6.68395 2.49083 7.13046 1.99207L7.35774 1.73818Z"
        fill={fill}
      />
      <path
        d="M5.96793 9L7.98932 11.0214L11.5267 6.47327"
        stroke={stroke}
        strokeWidth="1.43182"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_495_578">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
