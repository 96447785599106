import type { SVGProps } from 'react'

export const Logout: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 12L12 9M12 9L9 6M12 9L1 9M6 14C6 14.9319 6 15.3978 6.15224 15.7654C6.35523 16.2554 6.74432 16.6448 7.23438 16.8478C7.60192 17 8.06812 17 9 17H13.8C14.9201 17 15.48 17 15.9078 16.782C16.2841 16.5902 16.5905 16.2844 16.7822 15.908C17.0002 15.4802 17 14.9201 17 13.8V4.19995C17 3.07985 17.0002 2.51986 16.7822 2.09204C16.5905 1.71572 16.2841 1.40973 15.9078 1.21799C15.48 1 14.9201 1 13.8 1H9C8.06812 1 7.60192 1 7.23438 1.15224C6.74432 1.35523 6.35523 1.74456 6.15224 2.23462C6 2.60216 6 3.0681 6 3.99999"
      stroke={stroke}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
