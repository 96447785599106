/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetPostAssetImageResponse } from './GetPostAssetImageResponse';
import {
    GetPostAssetImageResponseFromJSON,
    GetPostAssetImageResponseFromJSONTyped,
    GetPostAssetImageResponseToJSON,
} from './GetPostAssetImageResponse';
import type { GetPostAssetVideoResponse } from './GetPostAssetVideoResponse';
import {
    GetPostAssetVideoResponseFromJSON,
    GetPostAssetVideoResponseFromJSONTyped,
    GetPostAssetVideoResponseToJSON,
} from './GetPostAssetVideoResponse';
import type { ImageVariantsResponse } from './ImageVariantsResponse';
import {
    ImageVariantsResponseFromJSON,
    ImageVariantsResponseFromJSONTyped,
    ImageVariantsResponseToJSON,
} from './ImageVariantsResponse';

/**
 * 
 * @export
 * @interface GetPreLoginPostResponse
 */
export interface GetPreLoginPostResponse {
    /**
     * 
     * @type {string}
     * @memberof GetPreLoginPostResponse
     */
    author: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPreLoginPostResponse
     */
    isVerified: boolean;
    /**
     * 
     * @type {ImageVariantsResponse}
     * @memberof GetPreLoginPostResponse
     */
    authorProfilePicture?: ImageVariantsResponse;
    /**
     * 
     * @type {string}
     * @memberof GetPreLoginPostResponse
     */
    postId: string;
    /**
     * 
     * @type {string}
     * @memberof GetPreLoginPostResponse
     */
    text: string;
    /**
     * 
     * @type {Date}
     * @memberof GetPreLoginPostResponse
     */
    publishedAt?: Date;
    /**
     * 
     * @type {Array<GetPostAssetImageResponse>}
     * @memberof GetPreLoginPostResponse
     */
    images?: Array<GetPostAssetImageResponse> | null;
    /**
     * 
     * @type {Array<GetPostAssetVideoResponse>}
     * @memberof GetPreLoginPostResponse
     */
    videos?: Array<GetPostAssetVideoResponse> | null;
}

/**
 * Check if a given object implements the GetPreLoginPostResponse interface.
 */
export function instanceOfGetPreLoginPostResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "author" in value;
    isInstance = isInstance && "isVerified" in value;
    isInstance = isInstance && "postId" in value;
    isInstance = isInstance && "text" in value;

    return isInstance;
}

export function GetPreLoginPostResponseFromJSON(json: any): GetPreLoginPostResponse {
    return GetPreLoginPostResponseFromJSONTyped(json, false);
}

export function GetPreLoginPostResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPreLoginPostResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'author': json['author'],
        'isVerified': json['isVerified'],
        'authorProfilePicture': !exists(json, 'authorProfilePicture') ? undefined : ImageVariantsResponseFromJSON(json['authorProfilePicture']),
        'postId': json['postId'],
        'text': json['text'],
        'publishedAt': !exists(json, 'publishedAt') ? undefined : (new Date(json['publishedAt'])),
        'images': !exists(json, 'images') ? undefined : (json['images'] === null ? null : (json['images'] as Array<any>).map(GetPostAssetImageResponseFromJSON)),
        'videos': !exists(json, 'videos') ? undefined : (json['videos'] === null ? null : (json['videos'] as Array<any>).map(GetPostAssetVideoResponseFromJSON)),
    };
}

export function GetPreLoginPostResponseToJSON(value?: GetPreLoginPostResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'author': value.author,
        'isVerified': value.isVerified,
        'authorProfilePicture': ImageVariantsResponseToJSON(value.authorProfilePicture),
        'postId': value.postId,
        'text': value.text,
        'publishedAt': value.publishedAt === undefined ? undefined : (value.publishedAt.toISOString()),
        'images': value.images === undefined ? undefined : (value.images === null ? null : (value.images as Array<any>).map(GetPostAssetImageResponseToJSON)),
        'videos': value.videos === undefined ? undefined : (value.videos === null ? null : (value.videos as Array<any>).map(GetPostAssetVideoResponseToJSON)),
    };
}

