import { create } from '@stylexjs/stylex'

const largeScreen = '@media (min-width: 768px)'

export const responsiveStyles = create({
  desktopFlex: {
    display: {
      default: 'none',
      [largeScreen]: 'flex',
    },
    alignItems: 'center',
  },
  phoneFlex: {
    display: {
      default: 'flex',
      [largeScreen]: 'none',
    },
    alignItems: 'center',
  },
})
