import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  ReactNode,
} from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import {
  Configuration,
  Auth0Api,
  StreamApi,
  LivestreamsApi,
  ChannelsApi,
  VideoApi,
  UserApi,
  CategoryApi,
  SearchApi,
  ContentReportApi,
  ActivityFeedApi,
  PostApi,
  AssetApi,
  UploadApi,
} from '../../client'
import { tokenRefreshMiddleware } from './middlewares/tokenRefreshMiddleware'
import { errorHandler } from './middlewares/errorHandlingMiddleware'
import { LoadingScreen } from '../../components/indicators/LoadingScreen'

interface ApiContextProps {
  auth0Api: Auth0Api
  streamApi: StreamApi
  liveStreamsApi: LivestreamsApi
  channelsApi: ChannelsApi
  videoApi: VideoApi
  userApi: UserApi
  categoriesApi: CategoryApi
  searchApi: SearchApi
  reportApi: ContentReportApi
  activityFeedApi: ActivityFeedApi
  postApi: PostApi
  uploadApi: UploadApi
  assetApi: AssetApi
}

const ApiContext = createContext<ApiContextProps | undefined>(undefined)

interface ApiProviderProps {
  children: ReactNode
}

const ApiProvider: React.FC<ApiProviderProps> = ({ children }) => {
  const {
    getAccessTokenSilently,
    isLoading,
    user,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0()
  const [token, setToken] = useState<string | null>(null)

  useEffect(() => {
    const fetchTokenOnce = async () => {
      if (!user) return

      try {
        const fetchedToken = await getAccessTokenSilently({
          cacheMode: user.email_verified === false ? 'off' : 'on',
        })

        setToken(fetchedToken)
      } catch (error) {
        console.error('Error fetching access token:', error)
      }
    }

    if (!isLoading && user && !token) {
      fetchTokenOnce()
    }
  }, [getAccessTokenSilently, isLoading, user, token])

  const apis = useMemo(() => {
    const headers: HeadersInit = { Authorization: `Bearer ${token}` }

    const configuration = new Configuration({
      basePath: import.meta.env.VITE_API_BASE_URL,
      credentials: 'include',
      headers,
    })

    const refreshMiddleware = tokenRefreshMiddleware(
      getAccessTokenSilently,
      setToken,
      isAuthenticated,
      loginWithRedirect
    )

    return {
      auth0Api: new Auth0Api(configuration)
        .withMiddleware(refreshMiddleware)
        .withMiddleware(errorHandler),
      streamApi: new StreamApi(configuration)
        .withMiddleware(refreshMiddleware)
        .withMiddleware(errorHandler),
      liveStreamsApi: new LivestreamsApi(configuration)
        .withMiddleware(refreshMiddleware)
        .withMiddleware(errorHandler),
      channelsApi: new ChannelsApi(configuration)
        .withMiddleware(refreshMiddleware)
        .withMiddleware(errorHandler),
      videoApi: new VideoApi(configuration)
        .withMiddleware(refreshMiddleware)
        .withMiddleware(errorHandler),
      userApi: new UserApi(configuration)
        .withMiddleware(refreshMiddleware)
        .withMiddleware(errorHandler),
      categoriesApi: new CategoryApi(configuration)
        .withMiddleware(refreshMiddleware)
        .withMiddleware(errorHandler),
      searchApi: new SearchApi(configuration)
        .withMiddleware(refreshMiddleware)
        .withMiddleware(errorHandler),
      reportApi: new ContentReportApi(configuration)
        .withMiddleware(refreshMiddleware)
        .withMiddleware(errorHandler),
      activityFeedApi: new ActivityFeedApi(configuration)
        .withMiddleware(refreshMiddleware)
        .withMiddleware(errorHandler),
      postApi: new PostApi(configuration)
        .withMiddleware(refreshMiddleware)
        .withMiddleware(errorHandler),
      uploadApi: new UploadApi(configuration)
        .withMiddleware(refreshMiddleware)
        .withMiddleware(errorHandler),
      assetApi: new AssetApi(configuration)
        .withMiddleware(refreshMiddleware)
        .withMiddleware(errorHandler),
    }
  }, [token, getAccessTokenSilently, isAuthenticated, loginWithRedirect])

  if (isLoading || !apis) {
    return <LoadingScreen />
  }

  return <ApiContext.Provider value={apis}>{children}</ApiContext.Provider>
}

const useApi = (): ApiContextProps => {
  const context = useContext(ApiContext)
  if (!context) {
    throw new Error('useApi must be used within an ApiProvider')
  }
  return context
}

export { ApiProvider, useApi }
