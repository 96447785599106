/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ReportReason = {
    ViolenceOrHarmfulBehaviour: 'violenceOrHarmfulBehaviour',
    BullyingOrHarassment: 'bullyingOrHarassment',
    HateSpeechOrSymbols: 'hateSpeechOrSymbols',
    MiscategorizedContent: 'miscategorizedContent',
    SelfHarm: 'selfHarm',
    NudityOrSexualActivity: 'nudityOrSexualActivity',
    SpamScamFraudPhishingOrImpersonation: 'spamScamFraudPhishingOrImpersonation',
    IntellectualPropertyViolation: 'intellectualPropertyViolation',
    Terrorism: 'terrorism',
    ChildEndangerment: 'childEndangerment'
} as const;
export type ReportReason = typeof ReportReason[keyof typeof ReportReason];


export function ReportReasonFromJSON(json: any): ReportReason {
    return ReportReasonFromJSONTyped(json, false);
}

export function ReportReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportReason {
    return json as ReportReason;
}

export function ReportReasonToJSON(value?: ReportReason | null): any {
    return value as any;
}

