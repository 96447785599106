import { props } from '@stylexjs/stylex'
import { color } from '../../styles/token.stylex'
import { Close } from '../icons/Close'
import { styles } from './ToastContainer'

export const CloseButton: React.FC<{
  closeToast: (e: React.MouseEvent<HTMLElement>) => void
}> = ({ closeToast }) => (
  <div role="button" onClick={closeToast} {...props(styles.toastBase)}>
    <Close stroke={color.white} width="1.125rem" height="1.125rem" />
  </div>
)
