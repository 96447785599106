import type { SVGProps } from 'react'

export const HelpAndSupport: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 7L8 11L6 9M17 4.19995V8.16481C17 14.7331 12.0319 17.6778 9.92578 18.6312C9.7025 18.7322 9.59158 18.7828 9.33887 18.8262C9.17946 18.8536 8.82127 18.8536 8.66187 18.8262C8.40915 18.7828 8.29723 18.7322 8.07395 18.6312C5.9678 17.6778 1 14.7331 1 8.16479V4.19995C1 3.07985 1 2.51986 1.21799 2.09204C1.40973 1.71572 1.71547 1.40973 2.0918 1.21799C2.51962 1 3.08009 1 4.2002 1H13.8002C14.9203 1 15.48 1 15.9078 1.21799C16.2841 1.40973 16.5905 1.71572 16.7822 2.09204C17.0002 2.51986 17 3.07985 17 4.19995Z"
      stroke={stroke}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
