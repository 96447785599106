import { createContext, useContext } from 'react'

type UnderConstructionDialogContextProps = {
  openDialog: (clickupId?: string) => void
}

export const UnderConstructionDialogContext =
  createContext<UnderConstructionDialogContextProps>({
    openDialog: () => {},
  })

export const useUnderConstructionDialog = () =>
  useContext(UnderConstructionDialogContext)
