/* eslint-disable sonarjs/no-unstable-nested-components */
import { ToastContainer } from 'react-toastify'
import { create, props } from '@stylexjs/stylex'
import { color, spacing } from '../../styles/token.stylex'
import { fonts } from '../../styles/font-styles'
import React from 'react'
import { ToastIcon } from './ToastIcon'
import { CloseButton } from './CloseButton'

export const styles = create({
  toastBase: {
    cursor: 'pointer',
    padding: spacing.xxSmall,
  },
  success: {
    background: color.successBackgroundColor,
    color: color.successTextColor,
  },
  error: {
    background: color.errorBackgroundColor,
    color: color.errorTextColor,
  },
  info: {
    background: color.infoToastBackgroundColor,
    color: color.infoToastTextColor,
  },
})

export const StyledToastContainer: React.FC = () => (
  <ToastContainer
    toastClassName={(context) => {
      const toastType = context?.type ?? 'default'
      const toastStyle = props(
        toastType === 'default' && styles.info,
        toastType === 'info' && styles.info,
        toastType === 'success' && styles.success,
        toastType === 'error' && styles.error,
        styles.toastBase
      ).className

      return toastStyle + ' Toastify__toast'
    }}
    icon={({ type }) => <ToastIcon type={type} />}
    closeButton={CloseButton}
    bodyClassName={props(fonts.smallMedium).className}
    position="top-right"
    autoClose={5000}
    newestOnTop
    closeOnClick
    pauseOnFocusLoss
    pauseOnHover
    draggable
  />
)
