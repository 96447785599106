import React, { useState, useEffect } from 'react'
import { styled } from '../../stitches'
import { IconButton as Button } from '../../shared/components/Common/IconButton'
import { useApi } from '../../shared/utils/ApiContext'
import LoadingIcon from '../../assets/icons/Loading.svg?react'
import useLanguage from '../../shared/stores/useLanguage'
import { useMutation } from '../../shared/utils/AsyncUtils/useMutation'
import { toast } from 'react-toastify'

const EmailVerificationPrompt: React.FC = () => {
  const { t } = useLanguage()
  const { auth0Api } = useApi()
  const [isLoading, setIsLoading] = useState(false)
  const [cooldown, setCooldown] = useState(false)
  const [timeLeft, setTimeLeft] = useState(60)

  const resendVerificationEmail = useMutation(async () => {
    setIsLoading(true)

    await auth0Api.auth0ResendVerificationEmailPost().then(
      () => {
        toast.success(t('accountRelated.verifyEmailResendSuccess'))
        setIsLoading(false)
        setCooldown(true)
      },
      () => {
        toast.error(t('error.emailVerificationResendFailed'))
        setIsLoading(false)
      }
    )
  })

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (cooldown) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer)
            setCooldown(false)
            setTimeLeft(60)
            return 60
          }
          return prevTime - 1
        })
      }, 1000)
    }

    return () => clearInterval(timer)
  }, [cooldown])

  return (
    <VerificationContainer>
      <Title>{t('accountRelated.verifyEmailTitle')}</Title>
      <Description>{t('accountRelated.verifyEmailMessage')}</Description>
      {isLoading ? <LoadingIcon /> : null}
      <ButtonWrapper>
        <Button
          variant="primary"
          size="big"
          label={
            cooldown
              ? `${t('accountRelated.resendVerificationEmail')} (${timeLeft}s)`
              : t('accountRelated.resendVerificationEmail')
          }
          onClick={resendVerificationEmail.runWithErrorNotification}
          disabled={isLoading || cooldown}
        />
      </ButtonWrapper>
    </VerificationContainer>
  )
}

export default EmailVerificationPrompt

const VerificationContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
})

const Title = styled('h2', {
  marginBottom: '1rem',
  fontSize: '1.5rem',
})

const Description = styled('p', {
  fontSize: '1rem',
  marginBottom: '1.5rem',
  textAlign: 'justify',
})

const ButtonWrapper = styled('div', {
  paddingTop: '1rem',
  display: 'flex',
  gap: '20px',
  justifyContent: 'center',
  flexDirection: 'column',
  '@onlyDesktop': {
    flexDirection: 'row',
  },
})
