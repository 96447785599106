import type { SVGProps } from 'react'

export const Chart: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.0002 16H4.2002C3.08009 16 2.51962 16 2.0918 15.782C1.71547 15.5902 1.40973 15.2844 1.21799 14.908C1 14.4802 1 13.9201 1 12.8V1M19 3L13.1543 8.11496C12.4542 8.72755 12.1041 9.03387 11.7207 9.16103C11.2685 9.31102 10.7775 9.29465 10.3363 9.11491C9.96228 8.96253 9.63356 8.63368 8.97576 7.97589C8.33225 7.33238 8.0105 7.01062 7.64355 6.8584C7.21071 6.67884 6.72875 6.65687 6.28142 6.79651C5.90221 6.91489 5.55252 7.20621 4.8534 7.78882L1 11"
      stroke={stroke}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
