import { createContext, useContext } from 'react'
import { GetUserInfoResponse } from '../client'

type UserContextProps = {
  isLoading: boolean
  isError: boolean
  data?: GetUserInfoResponse
}

export const UserContext = createContext<UserContextProps>({
  isLoading: false,
  isError: false,
})

export const useUserContext = () => useContext(UserContext)
