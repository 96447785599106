import { Outlet } from 'react-router-dom'
import { create, props } from '@stylexjs/stylex'
import { Logo } from '../components/logos/Logo'
import { color, spacing } from '../styles/token.stylex'
import { PropsWithChildren } from 'react'

const styles = create({
  container: {
    background: color.woodsmoke,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '100vw',
    minHeight: '100vh',
    padding: `${spacing.normal} clamp(${spacing.normal}, 20vw, 15rem)`,
    gap: '2rem',
  },
})

export const ErrorLayout: React.FC<PropsWithChildren> = ({ children }) => (
  <div {...props(styles.container)}>
    <div>
      <Logo variant="larger" />
    </div>
    {children ? children : <Outlet />}
  </div>
)
