/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  GetChannelInfoResponse,
  GetChannelLoggedInUserResponse,
  GetChannelPromoPanelResponse,
  GetChannelRecommendedResponse,
  GetLivestreamResponse,
  UpdatePromoPanelOrderRequest,
  UpdatePromoPanelsOrderRequest,
} from '../models/index';
import {
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    GetChannelInfoResponseFromJSON,
    GetChannelInfoResponseToJSON,
    GetChannelLoggedInUserResponseFromJSON,
    GetChannelLoggedInUserResponseToJSON,
    GetChannelPromoPanelResponseFromJSON,
    GetChannelPromoPanelResponseToJSON,
    GetChannelRecommendedResponseFromJSON,
    GetChannelRecommendedResponseToJSON,
    GetLivestreamResponseFromJSON,
    GetLivestreamResponseToJSON,
    UpdatePromoPanelOrderRequestFromJSON,
    UpdatePromoPanelOrderRequestToJSON,
    UpdatePromoPanelsOrderRequestFromJSON,
    UpdatePromoPanelsOrderRequestToJSON,
} from '../models/index';

export interface ChannelsChannelIdPromoPanelsGetRequest {
    channelId: string;
}

export interface ChannelsPromoPanelsBulkOrderPutRequest {
    updatePromoPanelsOrderRequest?: UpdatePromoPanelsOrderRequest;
}

export interface ChannelsPromoPanelsOrderPutRequest {
    updatePromoPanelOrderRequest?: UpdatePromoPanelOrderRequest;
}

export interface ChannelsPromoPanelsPanelIdDeleteRequest {
    panelId: string;
}

export interface ChannelsPromoPanelsPanelIdGetRequest {
    panelId: string;
}

export interface ChannelsPromoPanelsPanelIdPutRequest {
    panelId: string;
    title?: string;
    description?: string;
    image?: Blob;
    imageLinkUrl?: string;
}

export interface ChannelsPromoPanelsPostRequest {
    title?: string;
    description?: string;
    image?: Blob;
    imageLinkUrl?: string;
}

export interface ChannelsSlugGetRequest {
    slug: string;
}

export interface ChannelsSlugLivestreamGetRequest {
    slug: string;
}

export interface ChannelsSlugMeGetRequest {
    slug: string;
}

/**
 * 
 */
export class ChannelsApi extends runtime.BaseAPI {

    /**
     * Retrieves a list of all promo panels associated with the specified channel.
     */
    async channelsChannelIdPromoPanelsGetRaw(requestParameters: ChannelsChannelIdPromoPanelsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetChannelPromoPanelResponse>>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling channelsChannelIdPromoPanelsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/channels/{channelId}/promo-panels`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetChannelPromoPanelResponseFromJSON));
    }

    /**
     * Retrieves a list of all promo panels associated with the specified channel.
     */
    async channelsChannelIdPromoPanelsGet(requestParameters: ChannelsChannelIdPromoPanelsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetChannelPromoPanelResponse>> {
        const response = await this.channelsChannelIdPromoPanelsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the display order of multiple promo panels within a channel in a bulk operation. Only the channel owner can perform this update.
     */
    async channelsPromoPanelsBulkOrderPutRaw(requestParameters: ChannelsPromoPanelsBulkOrderPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/channels/promo-panels/bulk-order`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePromoPanelsOrderRequestToJSON(requestParameters.updatePromoPanelsOrderRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Updates the display order of multiple promo panels within a channel in a bulk operation. Only the channel owner can perform this update.
     */
    async channelsPromoPanelsBulkOrderPut(requestParameters: ChannelsPromoPanelsBulkOrderPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.channelsPromoPanelsBulkOrderPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the display order of a specific promo panel within a channel. Only the channel owner can update the order.
     */
    async channelsPromoPanelsOrderPutRaw(requestParameters: ChannelsPromoPanelsOrderPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/channels/promo-panels/order`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePromoPanelOrderRequestToJSON(requestParameters.updatePromoPanelOrderRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Updates the display order of a specific promo panel within a channel. Only the channel owner can update the order.
     */
    async channelsPromoPanelsOrderPut(requestParameters: ChannelsPromoPanelsOrderPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.channelsPromoPanelsOrderPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes an existing promo panel within a channel. Only the channel owner is authorized to delete the promo panel.
     */
    async channelsPromoPanelsPanelIdDeleteRaw(requestParameters: ChannelsPromoPanelsPanelIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.panelId === null || requestParameters.panelId === undefined) {
            throw new runtime.RequiredError('panelId','Required parameter requestParameters.panelId was null or undefined when calling channelsPromoPanelsPanelIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/channels/promo-panels/{panelId}`.replace(`{${"panelId"}}`, encodeURIComponent(String(requestParameters.panelId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an existing promo panel within a channel. Only the channel owner is authorized to delete the promo panel.
     */
    async channelsPromoPanelsPanelIdDelete(requestParameters: ChannelsPromoPanelsPanelIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.channelsPromoPanelsPanelIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieves detailed information about a specific promo panel by its ID within a channel.
     */
    async channelsPromoPanelsPanelIdGetRaw(requestParameters: ChannelsPromoPanelsPanelIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChannelPromoPanelResponse>> {
        if (requestParameters.panelId === null || requestParameters.panelId === undefined) {
            throw new runtime.RequiredError('panelId','Required parameter requestParameters.panelId was null or undefined when calling channelsPromoPanelsPanelIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/channels/promo-panels/{panelId}`.replace(`{${"panelId"}}`, encodeURIComponent(String(requestParameters.panelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChannelPromoPanelResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves detailed information about a specific promo panel by its ID within a channel.
     */
    async channelsPromoPanelsPanelIdGet(requestParameters: ChannelsPromoPanelsPanelIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChannelPromoPanelResponse> {
        const response = await this.channelsPromoPanelsPanelIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an existing promo panel within a channel. Only the channel owner is authorized to update the promo panel.
     */
    async channelsPromoPanelsPanelIdPutRaw(requestParameters: ChannelsPromoPanelsPanelIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChannelPromoPanelResponse>> {
        if (requestParameters.panelId === null || requestParameters.panelId === undefined) {
            throw new runtime.RequiredError('panelId','Required parameter requestParameters.panelId was null or undefined when calling channelsPromoPanelsPanelIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.title !== undefined) {
            formParams.append('Title', requestParameters.title as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('Description', requestParameters.description as any);
        }

        if (requestParameters.image !== undefined) {
            formParams.append('Image', requestParameters.image as any);
        }

        if (requestParameters.imageLinkUrl !== undefined) {
            formParams.append('ImageLinkUrl', requestParameters.imageLinkUrl as any);
        }

        const response = await this.request({
            path: `/channels/promo-panels/{panelId}`.replace(`{${"panelId"}}`, encodeURIComponent(String(requestParameters.panelId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChannelPromoPanelResponseFromJSON(jsonValue));
    }

    /**
     * Updates an existing promo panel within a channel. Only the channel owner is authorized to update the promo panel.
     */
    async channelsPromoPanelsPanelIdPut(requestParameters: ChannelsPromoPanelsPanelIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChannelPromoPanelResponse> {
        const response = await this.channelsPromoPanelsPanelIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a new promo panel to the specified channel. Only authorized users (channel owners) can add promo panels.
     */
    async channelsPromoPanelsPostRaw(requestParameters: ChannelsPromoPanelsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChannelPromoPanelResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.title !== undefined) {
            formParams.append('Title', requestParameters.title as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('Description', requestParameters.description as any);
        }

        if (requestParameters.image !== undefined) {
            formParams.append('Image', requestParameters.image as any);
        }

        if (requestParameters.imageLinkUrl !== undefined) {
            formParams.append('ImageLinkUrl', requestParameters.imageLinkUrl as any);
        }

        const response = await this.request({
            path: `/channels/promo-panels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChannelPromoPanelResponseFromJSON(jsonValue));
    }

    /**
     * Adds a new promo panel to the specified channel. Only authorized users (channel owners) can add promo panels.
     */
    async channelsPromoPanelsPost(requestParameters: ChannelsPromoPanelsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChannelPromoPanelResponse> {
        const response = await this.channelsPromoPanelsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves the list of channels which are recommended.
     */
    async channelsRecommendedGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChannelRecommendedResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/channels/recommended`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChannelRecommendedResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves the list of channels which are recommended.
     */
    async channelsRecommendedGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChannelRecommendedResponse> {
        const response = await this.channelsRecommendedGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves information about a channel based on its slug.
     */
    async channelsSlugGetRaw(requestParameters: ChannelsSlugGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChannelInfoResponse>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling channelsSlugGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/channels/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChannelInfoResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves information about a channel based on its slug.
     */
    async channelsSlugGet(requestParameters: ChannelsSlugGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChannelInfoResponse> {
        const response = await this.channelsSlugGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves live stream information for the channel\'s slug.
     */
    async channelsSlugLivestreamGetRaw(requestParameters: ChannelsSlugLivestreamGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLivestreamResponse>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling channelsSlugLivestreamGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/channels/{slug}/livestream`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLivestreamResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves live stream information for the channel\'s slug.
     */
    async channelsSlugLivestreamGet(requestParameters: ChannelsSlugLivestreamGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLivestreamResponse> {
        const response = await this.channelsSlugLivestreamGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves channel information specific to the logged-in user by the channel\'s slug.
     */
    async channelsSlugMeGetRaw(requestParameters: ChannelsSlugMeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChannelLoggedInUserResponse>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling channelsSlugMeGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/channels/{slug}/me`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChannelLoggedInUserResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves channel information specific to the logged-in user by the channel\'s slug.
     */
    async channelsSlugMeGet(requestParameters: ChannelsSlugMeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChannelLoggedInUserResponse> {
        const response = await this.channelsSlugMeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
