import { Outlet } from 'react-router-dom'
import { create, props } from '@stylexjs/stylex'
import { Footer } from './footer/Footer'
import { Menu } from './menu/Menu'
import { useUserContext } from '../auth/user-context'
import { getMenuData } from './menu/menu-data'
import useLanguage from '../shared/stores/useLanguage'
import { elementSizes } from '../styles/token.stylex'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    display: 'flex',
  },
  sideColumn: {
    display: {
      default: 'none',
      [largeScreen]: 'block',
    },
    position: 'fixed',
    zIndex: 1000,
  },
  main: {
    marginLeft: {
      default: 0,
      [largeScreen]: elementSizes.sidebarWidth,
    },
    paddingBottom: {
      default: elementSizes.footerHeight,
      [largeScreen]: 0,
    },
    width: '100%', // Without this the main content is not full width (currently)
  },
})

export const MainLayout: React.FC = () => {
  const { data } = useUserContext()
  const { languageCode } = useLanguage()
  const { menuItems, footerItems, profile } = getMenuData(data, languageCode)

  return (
    <div {...props(styles.container)}>
      <div {...props(styles.sideColumn)}>
        <Menu items={menuItems} footerItems={footerItems} profile={profile} />
      </div>
      <div {...props(styles.main)}>
        <Outlet />
        <Footer />
      </div>
    </div>
  )
}
