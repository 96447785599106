import { create, props } from '@stylexjs/stylex'
import { IconButton } from '../../components/IconButton'
import { Bell } from '../../components/icons/Bell'
import { Mail } from '../../components/icons/Mail'
import { NotificationBell } from '../../components/icons/NotificationBell'
import { ProfileMenu } from '../desktop/ProfileMenu'
import { responsiveStyles } from './styles'
import { NavContext } from './types'

type LoggedInStateProps = {
  displayName: string
} & NavContext

const styles = create({
  profileMenuContainer: {
    marginLeft: '0.75rem',
  },
})

export const LoggedInState: React.FC<LoggedInStateProps> = ({
  avatarImageSrc,
  messageCount,
  unreadNotifications,
  onNotificationClick,
  onMailClick,
  displayName,
}) => {
  const notificationIcon = () =>
    unreadNotifications ? <NotificationBell /> : <Bell />
  const mailIcon = () => <Mail notificationCount={messageCount} />

  return (
    <div {...props(responsiveStyles.desktopFlex)}>
      <IconButton icon={notificationIcon} onClick={onNotificationClick} />
      <IconButton icon={mailIcon} onClick={onMailClick} />
      <div {...props(styles.profileMenuContainer)}>
        <ProfileMenu
          avatarImageSrc={avatarImageSrc}
          displayName={displayName}
        />
      </div>
    </div>
  )
}
