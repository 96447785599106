import type { SVGProps } from 'react'

export const NotificationBell: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
  fill = '#FFD700',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0468 3.304C13.3994 3.10634 12.7121 3 12 3C8.134 3 5 6.13401 5 9.99998V13.7224C5 13.9002 4.92939 14.0706 4.80371 14.1963L4.29297 14.7071C4.10544 14.8947 4 15.1489 4 15.4141V16C4 16.5523 4.44772 17 5 17H19C19.5523 17 20 16.5523 20 16V15.4142C20 15.149 19.8945 14.8946 19.707 14.707L19.1963 14.1963C19.0706 14.0706 19 13.9002 19 13.7224V10C19 9.82356 18.9936 9.64865 18.9805 9.4762M15 17V18C15 19.6569 13.6569 21 12 21C10.3431 21 9 19.6569 9 18V17H15ZM21 6C21 8.20914 19.2091 10 17 10C14.7909 10 13 8.20914 13 6C13 3.79086 14.7909 2 17 2C19.2091 2 21 3.79086 21 6Z"
      stroke={stroke}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="17" cy="6" r="5" fill={fill} />
  </svg>
)
