import { create, props } from '@stylexjs/stylex'
import { color, spacing } from '../styles/token.stylex'
import { fonts } from '../styles/font-styles'
import { Verified } from './icons/Verified'
import { VerifiedSecondary } from './icons/VerifiedSecondary'
import { TextLink } from './TextLink'

const styles = create({
  nameContainer: {
    display: 'flex',
    gap: spacing.extraSmall,
    alignItems: 'center',
  },
  customColor: (color: string) => ({ color: color }),
})

type DisplayNameProps = {
  text: string
  color?: string
  verification?: 'simple' | 'fancy'
  isSecondaryVerificationIcon?: boolean
  font?: keyof typeof fonts
  url?: string
}

export const DisplayName: React.FC<DisplayNameProps> = ({
  text,
  color: textColor = color.displayNameTextColor,
  verification,
  font = 'extraSmallRegular',
  url,
}) => {
  const displayNameContent = (
    <div {...props(styles.nameContainer)}>
      <span {...props(fonts[font], styles.customColor(textColor))}>{text}</span>
      {verification &&
        (verification === 'fancy' ? <VerifiedSecondary /> : <Verified />)}
    </div>
  )

  return url ? (
    <TextLink to={url} underline>
      {displayNameContent}
    </TextLink>
  ) : (
    displayNameContent
  )
}
