import type { SVGProps } from 'react'

export const Verified: React.FC<SVGProps<SVGElement>> = ({
  stroke = '#FFD700',
  fill = '#2F2F2F',
  height = '1.125rem',
  width = '1.125rem',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
  >
    <rect width="18" height="18" rx="9" fill={fill} />
    <path
      d="M5 9.18184L7.54538 11.7272L13 6.27283"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
