import { color } from '../../styles/token.stylex'
import { CheckedCircle } from '../icons/CheckedCircle'
import { Close } from '../icons/Close'

type ToastIconProps = {
  type: 'info' | 'success' | 'warning' | 'error' | 'default'
}

export const ToastIcon: React.FC<ToastIconProps> = ({ type }) => {
  if (type === 'info')
    return <CheckedCircle primary={color.mineShaft} secondary={color.gold} />
  if (type === 'success')
    return <CheckedCircle primary={color.pasterGreen} secondary={color.white} />
  if (type === 'error') return <Close stroke={color.torchRed} />

  return <></>
}
