import type { SVGProps } from 'react'

export const Star: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33496 8.33675C1.02171 8.04707 1.19187 7.52338 1.61557 7.47314L7.61914 6.76134C7.79182 6.74086 7.94181 6.63206 8.01465 6.47416L10.5469 0.984297C10.7256 0.596861 11.2764 0.596811 11.4551 0.984247L13.9873 6.47424C14.0601 6.63214 14.2092 6.74087 14.3818 6.76135L20.3857 7.47314C20.8094 7.52338 20.9795 8.0471 20.6662 8.33679L16.2279 12.4415C16.1002 12.5596 16.0433 12.7356 16.0771 12.9061L17.2551 18.8359C17.3383 19.2544 16.8929 19.578 16.5206 19.3696L11.2453 16.4167C11.0935 16.3318 10.9087 16.3317 10.757 16.4166L5.48109 19.3697C5.10878 19.5781 4.66294 19.2545 4.74609 18.836L5.92437 12.9061C5.95826 12.7355 5.90134 12.5596 5.77367 12.4416L1.33496 8.33675Z"
      stroke={stroke}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
