import type { SVGProps } from 'react'

export const Wallet: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2036 2.02725C13.4114 2.0829 13.588 2.21986 13.6936 2.40723L15.2674 5.2H16.48C17.2981 5.2 18 5.85047 18 6.7V16.5C18 17.3495 17.2981 18 16.48 18H3.52C2.70193 18 2 17.3495 2 16.5V6.7C2 5.85046 2.70193 5.2 3.52 5.2H7.36757L12.5893 2.11143C12.7745 2.00194 12.9959 1.9716 13.2036 2.02725ZM13.43 5.19848L12.7008 3.90446L10.5185 5.19524L13.43 5.19848ZM14.7991 6.8L3.6 6.8V16.4H16.4V14H14.2C12.8552 14 11.6 13.0115 11.6 11.6C11.6 10.1885 12.8552 9.2 14.2 9.2H16.4V6.8H14.8004C14.8 6.8 14.7996 6.8 14.7991 6.8ZM16.4 10.8H14.2C13.5565 10.8 13.2 11.2442 13.2 11.6C13.2 11.9558 13.5565 12.4 14.2 12.4H16.4V10.8Z"
      fill={stroke}
    />
  </svg>
)
