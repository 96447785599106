import { Suspense, lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import { routes } from './routes'

import { ErrorLayout } from '../layout/ErrorLayout'
import { LoadingScreen } from './../components/indicators/LoadingScreen'
import { RootLayout } from '../layout/RootLayout'
import { ProtectedRoute } from './ProtectedRoute'
import { MainLayout } from '../layout/MainLayout'

// Note: Lazy load pages to improve performance, check vite solution
const LazyPages = {
  NotFound: lazy(() => import('../layout/pages/NotFoundPage')),
  Login: lazy(() => import('../OnlyStream/pages/LoginPage')),
  Logout: lazy(() => import('../OnlyStream/pages/LogoutPage')),
  SignUp: lazy(() => import('../OnlyStream/pages/SignUpPage')),
  MyStream: lazy(() => import('../OnlyStream/pages/MyStreamPage')),
  MyProfile: lazy(() => import('../OnlyStream/pages/MyProfile')),
  ProfileMenu: lazy(() => import('../layout/menu/ProfileMenu')),
  ChannelPage: lazy(() => import('../OnlyStream/pages/ChannelPage')),
  VideoPage: lazy(() => import('../OnlyStream/pages/VideoPage')),
  StreamKey: lazy(() => import('../OnlyStream/pages/StreamKeyPage')),
  Callback: lazy(() => import('../OnlyStream/pages/CallbackPage')),
  StreamHome: lazy(() => import('../OnlyStream/pages/Home')),
  FeedsPage: lazy(() => import('../components/feeds/FeedsPage')),
  FeedsUploadPage: lazy(() => import('../OnlyStream/pages/FeedsPage')),
  StyleGuidePage: lazy(() => import('../style-guide/pages/StyleGuidePage')),
  StoryblokStatic: lazy(
    () => import('../OnlyStream/pages/StroyblokStaticPage')
  ),
}

export const Router = () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        {/* Main layout */}
        <Route element={<RootLayout />}>
          <Route element={<MainLayout />}>
            {/* Public Routes */}
            <Route path={routes.home} element={<LazyPages.StreamHome />} />
            <Route
              path={routes.auth.callback}
              element={<LazyPages.Callback />}
            />

            <Route element={<LazyPages.ChannelPage />}>
              <Route path={routes.channel.homeFull(':slug')} />
              <Route path={routes.channel.homeShort(':slug')} />
            </Route>
            <Route element={<LazyPages.VideoPage />}>
              <Route path={routes.channel.videoShort(':slug', ':videoId')} />
              <Route path={routes.channel.videoFull(':slug', ':videoId')} />
            </Route>
            <Route path={routes.feeds} element={<LazyPages.FeedsPage />} />

            {/* Storyblok static pages */}
            <Route element={<LazyPages.StoryblokStatic />}>
              <Route path={routes.communityGuidelines} />
              <Route path={routes.termsOfService} />
              <Route path={routes.dmcaPolicy} />
              <Route path={routes.privacyPolicy} />
              <Route path={routes.howToEmbed} />
            </Route>

            {/* Protected Routes */}
            <Route element={<ProtectedRoute />}>
              <Route
                path={routes.feedsUpload}
                element={<LazyPages.FeedsUploadPage />}
              />
              <Route
                path={routes.profileRoutes.myStream}
                element={<LazyPages.MyStream />}
              />
              <Route
                path={routes.profileRoutes.myProfile}
                element={<LazyPages.MyProfile />}
              />
              <Route
                path={routes.profileRoutes.streamKey}
                element={<LazyPages.StreamKey />}
              />
            </Route>
          </Route>

          <Route element={<ProtectedRoute />}>
            <Route path={routes.profile} element={<LazyPages.ProfileMenu />} />
          </Route>
        </Route>

        <Route element={<RootLayout />}>
          <Route
            path={routes.styleGuide}
            element={<LazyPages.StyleGuidePage />}
          />
        </Route>

        {/* Empty layout */}
        <Route path={routes.signup} element={<LazyPages.SignUp />} />
        <Route path={routes.logout} element={<LazyPages.Logout />} />
        <Route path={routes.login} element={<LazyPages.Login />} />

        {/* Error layout */}
        <Route element={<ErrorLayout />}>
          <Route path="*" element={<LazyPages.NotFound />} />
        </Route>
      </Routes>
    </Suspense>
  )
}
