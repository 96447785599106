/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MediaType = {
    Image: 'image',
    Video: 'video'
} as const;
export type MediaType = typeof MediaType[keyof typeof MediaType];


export function MediaTypeFromJSON(json: any): MediaType {
    return MediaTypeFromJSONTyped(json, false);
}

export function MediaTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MediaType {
    return json as MediaType;
}

export function MediaTypeToJSON(value?: MediaType | null): any {
    return value as any;
}

