import type { SVGProps } from 'react'

export const Media: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 13.4445L5.2382 8.49989C5.61488 8.06043 5.8033 7.84075 6.02683 7.7615C6.22298 7.69196 6.43721 7.69348 6.63238 7.76573C6.85479 7.84808 7.04005 8.07033 7.41059 8.51497L9.78074 11.3592C10.1265 11.7741 10.2995 11.9816 10.5098 12.065C10.6946 12.1383 10.8984 12.1475 11.089 12.0913C11.306 12.0273 11.497 11.8363 11.8789 11.4543L12.3207 11.0125C12.7096 10.6236 12.9041 10.4292 13.1242 10.3657C13.3175 10.3099 13.5241 10.3213 13.7101 10.3981C13.9218 10.4855 14.0935 10.7003 14.4371 11.1298L17 14.3334M17 3.8444V14.1555C17 15.1512 17.0002 15.6491 16.8064 16.0294C16.636 16.3639 16.3637 16.6358 16.0291 16.8062C15.6489 17 15.1514 17 14.1557 17H3.84462C2.84897 17 2.35077 17 1.97049 16.8062C1.63598 16.6358 1.36421 16.3639 1.19377 16.0294C1 15.6491 1 15.1512 1 14.1555V3.8444C1 2.84875 1 2.35099 1.19377 1.9707C1.36421 1.63619 1.63598 1.36421 1.97049 1.19377C2.35077 1 2.84897 1 3.84462 1H14.1557C15.1514 1 15.6489 1 16.0291 1.19377C16.3637 1.36421 16.636 1.63619 16.8064 1.9707C17.0002 2.35099 17 2.84875 17 3.8444ZM11.6667 6.33333C11.1757 6.33333 10.7778 5.93536 10.7778 5.44444C10.7778 4.95352 11.1757 4.55556 11.6667 4.55556C12.1576 4.55556 12.5556 4.95352 12.5556 5.44444C12.5556 5.93536 12.1576 6.33333 11.6667 6.33333Z"
      stroke={stroke}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
