import { Menu, MenuItem } from '@szhsin/react-menu'
import { create, props } from '@stylexjs/stylex'
import { ContextMenuItem } from './types'
import { fonts } from '../styles/font-styles'
import { PropsWithChildren } from 'react'
import { color, spacing } from '../styles/token.stylex'

const styles = create({
  menu: {
    border: `1px solid ${color.contextMenuBackgroundColor}`,
    overflow: 'hidden',
    minWidth: '14em',
    borderRadius: spacing.extraSmall,
    backgroundColor: color.contextMenuBackgroundColor,
    zIndex: 100,
  },
  menuButton: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  menuItem: {
    paddingInline: `${spacing.large}`,
    paddingBlock: `${spacing.extraSmall}`,
    display: 'flex',
    alignItems: 'center',
    gap: spacing.small,
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    color: {
      default: color.contextMenuItemTextColor,
      ':hover': color.contextMenuItemHoverTextColor,
    },
    backgroundColor: {
      default: color.contextMenuItemBackgroundColor,
    },
  },
  itemsContainer: {
    paddingBlock: `${spacing.extraSmall}`,
    backgroundColor: {
      default: color.contextMenuItemBackgroundColor,
    },
  },
  header: {
    borderBottom: `1px solid ${color.dividerBackground}`,
    marginBottom: `${spacing.extraSmall}`,
    paddingInline: `${spacing.large}`,
    paddingTop: `${spacing.extraSmall}`,
    paddingBottom: `${spacing.small}`,
    display: 'flex',
    justifyContent: 'center',
  },
})

type ContextMenuProps = PropsWithChildren<{
  items: ContextMenuItem[]
  menuHeader?: React.ReactNode
}>

export const ContextMenu: React.FC<ContextMenuProps> = ({
  items,
  children,
  menuHeader,
}) => (
  <Menu
    menuButton={<div {...props(styles.menuButton)}>{children}</div>}
    direction="bottom"
    align="end"
    menuClassName={props(styles.menu).className}
  >
    <div {...props(styles.itemsContainer)}>
      {menuHeader && <div {...props(styles.header)}>{menuHeader}</div>}
      {items.map((item, index) => (
        <MenuItem
          key={index}
          onClick={item.action}
          {...props(styles.menuItem, fonts.normalRegular)}
        >
          {item.icon && <item.icon />}
          {item.label}
        </MenuItem>
      ))}
    </div>
  </Menu>
)
