import type { SVGProps } from 'react'

export const Profile: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 19.2428C15 15.3768 11.866 12.2428 8 12.2428C4.13401 12.2428 1 15.3768 1 19.2428M8 9.2428C5.79086 9.2428 4 7.45194 4 5.2428C4 3.03366 5.79086 1.2428 8 1.2428C10.2091 1.2428 12 3.03366 12 5.2428C12 7.45194 10.2091 9.2428 8 9.2428Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
