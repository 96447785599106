import { create, props } from '@stylexjs/stylex'
import { LoginAndRegisterButtons } from '../../auth/LoginAndRegisterButtons'
import { PhoneLoginButton } from '../../auth/PhoneLoginButton'
import { responsiveStyles } from './styles'

const styles = create({
  mobileLoginButtonContainer: {
    position: 'relative',
    left: '0.75rem',
  },
})

export const LoggedOutState: React.FC = () => (
  <div>
    <div {...props(responsiveStyles.desktopFlex)}>
      <LoginAndRegisterButtons />
    </div>
    <div
      {...props(styles.mobileLoginButtonContainer, responsiveStyles.phoneFlex)}
    >
      <PhoneLoginButton />
    </div>
  </div>
)
