import { useNavigate } from 'react-router-dom'
import { AvatarImage } from '../../components/avatars/AvatarImage'
import { ContextMenu } from '../../components/ContextMenu'
import useLanguage from '../../shared/stores/useLanguage'
import { ContextMenuItem } from '../../components/types'
import { Profile } from '../../components/icons/Profile'
import { HelpAndSupport } from '../../components/icons/HelpAndSupport'
import { Logout } from '../../components/icons/Logout'
import { TextLink } from '../../components/TextLink'

type ProfileMenuProps = {
  avatarImageSrc?: string
  displayName: string
}

export const ProfileMenu: React.FC<ProfileMenuProps> = ({
  avatarImageSrc,
  displayName,
}) => {
  const navigate = useNavigate()
  const { t, languageCode } = useLanguage()

  const profileIcon = () => <Profile width={'1.25rem'} height={'1.25rem'} />
  const helpAndSupportIcon = () => (
    <HelpAndSupport width={'1.25rem'} height={'1.25rem'} />
  )
  const logoutIcon = () => <Logout width={'1.25rem'} height={'1.25rem'} />

  const menuItems: ContextMenuItem[] = [
    {
      label: `${t('userMenu.profile')}`,
      action: () => navigate('/profile/my-profile'),
      icon: profileIcon,
    },
    {
      label: `${t('userMenu.helpAndSupport')}`,
      action: () =>
        window.open(
          `https://help.spinstream-qa.io/${languageCode}`,
          '_blank',
          'noopener'
        ),
      icon: helpAndSupportIcon,
    },
    {
      label: `${t('userMenu.logout')}`,
      action: () => navigate('/logout'),
      icon: logoutIcon,
    },
  ]

  const menuHeader = (
    <TextLink to={`/${displayName}`} font="normalRegular">
      {`@${displayName}`}
    </TextLink>
  )

  return (
    <ContextMenu items={menuItems} menuHeader={menuHeader}>
      <AvatarImage
        src={avatarImageSrc}
        alt={t('streamingGeneral.profilePictureAltText')}
        variant="small"
      />
    </ContextMenu>
  )
}
