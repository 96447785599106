import React, { useRef, useState } from 'react'
import { UnderConstructionDialog } from './UnderConstructionDialog'
import { UnderConstructionDialogContext } from './under-construction-context'

export const UnderConstructionDialogProvider: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const dialogRef = useRef<HTMLDialogElement>(null)
  const [clickupId, setClickupId] = useState<string>()

  const openDialog = (id?: string) => {
    setClickupId(id)
    dialogRef.current?.showModal()
  }

  return (
    <UnderConstructionDialogContext.Provider value={{ openDialog }}>
      {children}
      <UnderConstructionDialog ref={dialogRef} clickupId={clickupId} />
    </UnderConstructionDialogContext.Provider>
  )
}
