/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssetType } from './AssetType';
import {
    AssetTypeFromJSON,
    AssetTypeFromJSONTyped,
    AssetTypeToJSON,
} from './AssetType';
import type { MediaType } from './MediaType';
import {
    MediaTypeFromJSON,
    MediaTypeFromJSONTyped,
    MediaTypeToJSON,
} from './MediaType';
import type { VideoVariantResponse } from './VideoVariantResponse';
import {
    VideoVariantResponseFromJSON,
    VideoVariantResponseFromJSONTyped,
    VideoVariantResponseToJSON,
} from './VideoVariantResponse';

/**
 * 
 * @export
 * @interface UploadVideoResponse
 */
export interface UploadVideoResponse {
    /**
     * Unique identifier for the uploaded asset.
     * @type {string}
     * @memberof UploadVideoResponse
     */
    assetId?: string;
    /**
     * 
     * @type {AssetType}
     * @memberof UploadVideoResponse
     */
    assetType?: AssetType;
    /**
     * 
     * @type {MediaType}
     * @memberof UploadVideoResponse
     */
    mediaType?: MediaType;
    /**
     * 
     * @type {VideoVariantResponse}
     * @memberof UploadVideoResponse
     */
    video?: VideoVariantResponse;
}

/**
 * Check if a given object implements the UploadVideoResponse interface.
 */
export function instanceOfUploadVideoResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UploadVideoResponseFromJSON(json: any): UploadVideoResponse {
    return UploadVideoResponseFromJSONTyped(json, false);
}

export function UploadVideoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadVideoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assetId': !exists(json, 'assetId') ? undefined : json['assetId'],
        'assetType': !exists(json, 'assetType') ? undefined : AssetTypeFromJSON(json['assetType']),
        'mediaType': !exists(json, 'mediaType') ? undefined : MediaTypeFromJSON(json['mediaType']),
        'video': !exists(json, 'video') ? undefined : VideoVariantResponseFromJSON(json['video']),
    };
}

export function UploadVideoResponseToJSON(value?: UploadVideoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assetId': value.assetId,
        'assetType': AssetTypeToJSON(value.assetType),
        'mediaType': MediaTypeToJSON(value.mediaType),
        'video': VideoVariantResponseToJSON(value.video),
    };
}

