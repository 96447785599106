import { create, props } from '@stylexjs/stylex'
import { NavLink } from 'react-router-dom'
import { color, spacing } from '../styles/token.stylex'
import { fonts } from '../styles/font-styles'
import { MouseEventHandler, PropsWithChildren } from 'react'

const styles = create({
  container: {
    display: 'inline-block',
    width: '100%',
    padding: `${spacing.xxxSmall} ${spacing.extraSmall}`,
    cursor: 'pointer',
    margin: '0 auto',
    borderRadius: spacing.extraSmall,
    textDecoration: 'none',
    position: 'relative',
    color: {
      default: color.linkColorDefault,
      ':hover': color.linkColorHover,
      ':active': color.linkColorActive,
      ':focus': color.linkColorFocus,
    },
  },
  routeActive: {
    color: color.linkColorActive,
  },
  disabled: {
    color: color.linkColorDisabled,
    cursor: 'default',
    pointerEvents: 'none',
  },
  tab: {
    '::after': {
      content: '""',
      position: 'absolute',
      bottom: '-2px',
      left: 0,
      width: '100%',
      height: '2px',
      backgroundColor: 'transparent',
      transition: 'all 0.2s ease',
      borderRadius: '2px',
    },
    color: {
      default: color.tabTextColorDefault,
      ':hover': color.linkColorDefault,
    },
    paddingInline: spacing.large,
  },
  tabIcon: {
    width: null,
    color: {
      default: color.tabTextColorDefault,
      ':hover': color.linkColorDefault,
    },
  },
  tabIconActive: {
    color: color.linkColorDefault,
  },
  tabActive: {
    '::after': {
      backgroundColor: color.primary,
    },
    color: color.linkColorDefault,
  },
})

type LinkProps = {
  disabled?: boolean
  to: string
  exact?: boolean
  isExternal?: boolean
  onClick?: MouseEventHandler<HTMLAnchorElement>
  variant?: 'tab' | 'tabIcon'
}

export const Link: React.FC<PropsWithChildren<LinkProps>> = ({
  disabled = false,
  to,
  exact = false,
  isExternal = false,
  variant,
  onClick,
  children,
}) => {
  if (disabled)
    return (
      <div {...props(styles.container, fonts.smallSemiBold, styles.disabled)}>
        {children}
      </div>
    )

  return (
    <NavLink
      to={to}
      onClick={onClick}
      target={isExternal ? '_blank' : undefined}
      rel={isExternal ? 'noopener noreferrer' : undefined}
      className={({ isActive }) =>
        props(
          styles.container,
          fonts.smallSemiBold,
          variant && styles[variant],
          isActive && styles[`${variant || 'route'}Active`]
        ).className
      }
      end={exact}
    >
      {children}
    </NavLink>
  )
}
