import type { SVGProps } from 'react'

export const ButtonLoader: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.125rem',
  width = '1.125rem',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_1175_2057)">
        <mask
          id="mask0_1175_2057"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="18"
          height="18"
        >
          <path
            d="M9 1C13.4183 0.999999 17 4.58172 17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C0.999999 4.58172 4.58172 1 9 1Z"
            stroke={stroke}
            strokeWidth="2"
            strokeLinejoin="round"
          />
        </mask>
        <g mask="url(#mask0_1175_2057)">
          <g clipPath="url(#clip1_1175_2057)">
            <rect width="9" height="27" fill={stroke} />
          </g>
          <g clipPath="url(#clip2_1175_2057)">
            <path d="M0 9H27V18H0V9Z" fill={stroke} />
          </g>
          <g clipPath="url(#clip3_1175_2057)">
            <rect x="9" y="-9" width="9" height="27" fill={stroke} />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1175_2057">
          <rect width="18" height="18" fill={stroke} />
        </clipPath>
        <clipPath id="clip1_1175_2057">
          <rect width="9" height="9" fill={stroke} />
        </clipPath>
        <clipPath id="clip2_1175_2057">
          <rect width="9" height="9" fill={stroke} transform="translate(0 9)" />
        </clipPath>
        <clipPath id="clip3_1175_2057">
          <rect width="9" height="9" fill={stroke} transform="translate(9 9)" />
        </clipPath>
      </defs>
    </svg>
  )
}
