import type { SVGProps } from 'react'

export const Plus: React.FC<SVGProps<SVGElement>> = ({
  height = '1.5rem',
  width = '1.5rem',
  fill = 'currentColor',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 41 40"
    fill="none"
  >
    <circle opacity="0.6" cx="20.5" cy="20" r="20" fill={fill} />
    <circle cx="20.5" cy="20" r="18" fill={fill} />
    <rect x="11.5" y="19.1" width="18" height="1.8" fill="white" />
    <rect
      x="21.4"
      y="11"
      width="18"
      height="1.8"
      transform="rotate(90 21.4 11)"
      fill="white"
    />
  </svg>
)
