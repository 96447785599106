import { MediaConfig, styled } from '../../../stitches'
import { ComponentProps, MouseEventHandler, ReactNode } from 'react'
import LoadingIcon from '../../../assets/icons/Loading.svg?react'

export function IconButton(props: {
  onClick?: MouseEventHandler<HTMLButtonElement>
  label?: ReactNode
  icon?: ReactNode
  iconOnly?: MediaConfig<boolean>
  disabled?: boolean
  endAdornment?: ReactNode
  isLoading?: boolean
  variant?: ComponentProps<typeof StyledButton>['variant']
  size?: ComponentProps<typeof StyledButton>['size']
  type?: ComponentProps<typeof StyledButton>['type']
}) {
  const iconOnly = !props.label || props.iconOnly
  return (
    <StyledButton
      disabled={props.disabled}
      type={props.type || 'button'}
      variant={props.variant}
      onClick={props.onClick}
      size={props.size}
    >
      {props.icon && (
        <IconWrapper transparent={props.isLoading}>{props.icon}</IconWrapper>
      )}
      <LabelWrapper transparent={props.isLoading} hidden={iconOnly}>
        {props.label}
      </LabelWrapper>
      {props.isLoading && (
        <LoadingIconWrapper>
          <LoadingIcon />
        </LoadingIconWrapper>
      )}
      {props.endAdornment && <Adornment>{props.endAdornment}</Adornment>}
    </StyledButton>
  )
}
const LoadingIconWrapper = styled('div', {
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  minWidth: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
const IconWrapper = styled('div', {
  flex: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'opacity 500ms',
  verticalAlign: 'middle',
  variants: {
    transparent: {
      true: {
        opacity: 0,
      },
    },
  },
})
const LabelWrapper = styled('div', {
  alignContent: 'center',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  textTransform: 'capitalize',
  transition: 'opacity 500ms',
  verticalAlign: 'middle',
  variants: {
    hidden: {
      true: {
        display: 'none',
      },
      false: {
        display: 'block',
      },
    },
    transparent: {
      true: {
        opacity: 0,
      },
    },
  },
})

const ICON_BUTTON_BORDER_RADIUS = '0.3rem'
const PADDING_VERTICAL = '0.6rem'
const PADDING_HORIZONTAL = '0.6rem'
const StyledButton = styled('button', {
  position: 'relative',
  fontFamily: 'Inter, sans-serif',
  padding: `0.5rem 0.6rem`,
  fontWeight: 600,
  fontSize: '0.9rem',
  gap: '0.375rem',
  cursor: 'pointer',
  borderRadius: ICON_BUTTON_BORDER_RADIUS,
  border: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#3f4448',
  color: '#ffffff',
  '&:hover': {
    background: '#313538',
  },
  transition: 'background 0.2s',
  verticalAlign: 'middle',
  variants: {
    size: {
      small: {
        padding: '0.25rem 0.375rem',
        fontSize: '0.75rem',
        lineHeight: '1',
        fontWeight: '600',
      },
      normal: {},
      big: {
        padding: '0.5rem 0.625rem',
        fontSize: '1rem',
        lineHeight: '1.25',
        fontWeight: '600',
      },
      bigger: {
        lineHeight: '1.6',
        fontSize: '1rem',
        height: '42px',
        padding: `0.5rem 1rem`,
        borderRadius: '0.5rem',
      },
    },
    variant: {
      primary: {
        background: '#53fc18',
        color: '#070809',
        '&:hover': {
          background: '#44C714',
        },
        '&:disabled': {
          opacity: 0.2,
          cursor: 'initial',
          background: '#53fc18',
          '&:hover': {
            background: '#53fc18',
          },
        },
      },
      default: {
        '&:disabled': {
          color: '#888',
          cursor: 'initial',
          background: '#3f4448',
          '&:hover': {
            background: '#3f4448',
          },
        },
      },
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'normal',
  },
})
const Adornment = styled('div', {
  display: 'flex',
  justifyContent: 'stretch',
  alignItems: 'stretch',
  alignSelf: 'stretch',
  margin: `-${PADDING_VERTICAL} -${PADDING_HORIZONTAL}`,
})
