import { create, props } from '@stylexjs/stylex'
import { color, elementSizes, spacing } from '../../styles/token.stylex'
import { Link } from '../../components/Link'
import { fonts } from '../../styles/font-styles'
import { FullCreatorInfo } from '../../components/FullCreatorInfo'
import useLanguage, { LanguageKey } from '../../shared/stores/useLanguage'
import { FullCreator } from '../../components/types'
import { useUnderConstructionDialog } from '../../style-guide/components/under-construction/under-construction-context'
import { MenuItem } from './types'
import { TextLink } from '../../components/TextLink'
import { ArrowLeftIcon } from '@radix-ui/react-icons'
import { responsiveStyles } from '../navbar/styles'
import { useNavigate } from 'react-router-dom'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.extraSmall,
    width: elementSizes.sidebarWidth,
    height: `calc(100dvh - ${elementSizes.headerHeight})`,
    padding: spacing.normal,
    backgroundColor: color.codGray,
  },
  separator: {
    margin: `${spacing.small} 0`,
    height: '1px',
    backgroundColor: color.mineShaft,
  },
  footer: {
    flex: 1,
    alignContent: 'flex-end',
    paddingTop: spacing.large,
    borderTop: `1px solid ${color.mineShaft}`,
  },
  linkContent: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.small,
    color: {
      default: color.dustyGray,
      ':hover': color.gold,
    },
  },
  label: {
    color: {
      default: color.white,
      ':hover': color.gold,
    },
  },
  header: {
    display: {
      default: 'flex',
      [largeScreen]: 'none',
    },
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: spacing.large,
    paddingBottom: spacing.large,
  },
  profile: {
    width: '100%',
    height: `calc(100dvh - ${elementSizes.headerHeight} - ${elementSizes.footerHeight})`,
    maxHeight: '100%',
  },
  underConstructionContainer: {
    padding: `${spacing.xxxSmall} ${spacing.extraSmall}`,
    cursor: 'pointer',
  },
  menuTitle: {
    paddingBlock: `${spacing.small} ${spacing.large}`,
  },
})

type MenuProps = {
  items: MenuItem[]
  footerItems?: MenuItem[]
  profile?: FullCreator
  variant?: 'profile'
}

const renderMenuItems = (
  items: MenuItem[],
  t: (key: LanguageKey) => string,
  openDialog: (clickupId: string) => void
) =>
  items.map(
    ({ icon: Icon, labelKey, route, isExternal, clickUpId, hasSeparator }) => (
      <div key={labelKey}>
        {clickUpId ? ( // This will be deleted when all menu items are implemented
          <div
            {...props(styles.underConstructionContainer)}
            onClick={() => openDialog(clickUpId)}
          >
            <div {...props(styles.linkContent)}>
              <Icon width="1.3rem" height="1.3rem" />
              <div {...props(styles.label, fonts.largeRegular)}>
                {t(labelKey)}
              </div>
            </div>
          </div>
        ) : (
          <Link to={route} isExternal={isExternal}>
            <div {...props(styles.linkContent)}>
              <Icon width="1.3rem" height="1.3rem" />
              <div {...props(styles.label, fonts.largeRegular)}>
                {t(labelKey)}
              </div>
            </div>
          </Link>
        )}
        {hasSeparator && <div {...props(styles.separator)} />}
      </div>
    )
  )

export const Menu: React.FC<MenuProps> = ({
  items,
  footerItems,
  profile,
  variant,
}) => {
  const { t } = useLanguage()
  const { openDialog } = useUnderConstructionDialog()
  const navigate = useNavigate()

  return (
    <nav {...props(styles.container, variant && styles[variant])}>
      {profile && (
        <div {...props(responsiveStyles.phoneFlex, styles.header)}>
          <div>
            <TextLink
              onClick={() => navigate(-1)}
              variant="white"
              titleFont="xlSemiBold"
            >
              <ArrowLeftIcon /> {t('menu.title')}
            </TextLink>
          </div>
          <div>
            <FullCreatorInfo
              displayName={profile.displayName}
              isVerified={profile.isVerified}
              profilePictureUrl={profile.profilePictureUrl}
              avatarImageSize="medium"
              profileUrl={profile.profileUrl}
              fullName={profile.fullName}
            />
          </div>
        </div>
      )}
      {renderMenuItems(items, t, openDialog)}
      {footerItems && (
        <div {...props(styles.footer)}>
          {renderMenuItems(footerItems, t, openDialog)}
        </div>
      )}
    </nav>
  )
}
