import React, { forwardRef } from 'react'
import { create, props } from '@stylexjs/stylex'
import { Dialog } from '../../../components/dialog/Dialog'
import useLanguage from '../../../shared/stores/useLanguage'
import { TextLink } from '../../../components/TextLink'
import { fonts } from '../../../styles/font-styles'
import { spacing } from '../../../styles/token.stylex'

const styles = create({
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    paddingRight: spacing.large,
    minHeight: '5rem',
  },
})

type UnderConstructionDialogProps = {
  clickupId?: string
}

// eslint-disable-next-line react/display-name
export const UnderConstructionDialog = forwardRef<
  HTMLDialogElement,
  UnderConstructionDialogProps
>(({ clickupId }, ref) => {
  const { t } = useLanguage()

  return (
    <Dialog
      ref={ref}
      title={t('shared.components.dialog.titles.information')}
      cancel={{
        label: `${t('shared.buttons.close')}`,
        action: () =>
          (ref as React.RefObject<HTMLDialogElement>).current?.close(),
      }}
    >
      <div>
        {clickupId ? (
          <div {...props(styles.messageContainer, fonts.extraSmallRegular)}>
            {t('temp.underConstruction.message')}
            <TextLink
              to={`https://app.clickup.com/t/${clickupId}`}
              isExternal
            >{`Clickup: ${clickupId}`}</TextLink>
          </div>
        ) : (
          t('temp.underConstruction.message')
        )}
      </div>
    </Dialog>
  )
})
