import {
  ErrorCodes,
  Middleware,
  ModelError,
  ResponseContext,
} from '../../../client'
import { ApiError } from '../apiError'

export const errorHandler: Middleware = {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  post: async (context: ResponseContext) => {
    if (context.response.status >= 400) {
      try {
        const contentType = context.response.headers.get('content-type')
        let errorResponse

        if (contentType?.includes('application/json')) {
          errorResponse = await context.response.json()
        } else {
          errorResponse = await context.response.text()
        }

        console.error('FULL_RESPONSE:', JSON.stringify(errorResponse))

        if (typeof errorResponse === 'string') {
          throw new ApiError({
            error: {
              code: ErrorCodes.TechnicalError,
              message: errorResponse,
            },
          })
        } else {
          const { error } = errorResponse as { error: ModelError }
          throw new ApiError({ error })
        }
      } catch (error) {
        const customError = error instanceof ApiError ? error.error : undefined

        console.error('BACKEND_ERROR:', JSON.stringify(customError))

        if (!customError) {
          console.error('UNKNOWN_ERROR:', JSON.stringify(error))
        }

        throw new ApiError({
          error: customError || {
            code: ErrorCodes.TechnicalError,
            message: JSON.stringify(error),
          },
        })
      }
    }
  },
}
