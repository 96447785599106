import * as RadixTooltip from '@radix-ui/react-tooltip'
import { ErrorBoundary } from 'react-error-boundary'
import { Router } from './router/Router'
import { Provider } from 'jotai'
import { store } from './store'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { UserContextProvider } from './auth/UserContextProvider'
import React from 'react'
import { GeneralErrorPage } from './layout/pages/GeneralErrorPage'
import { UnderConstructionDialogProvider } from './style-guide/components/under-construction/UnderConstructionContextProvider'

const queryClient = new QueryClient()

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <UserContextProvider>
        <UnderConstructionDialogProvider>
          <Provider store={store}>
            <ErrorBoundary FallbackComponent={GeneralErrorPage}>
              <RadixTooltip.Provider>
                <Router />
              </RadixTooltip.Provider>
            </ErrorBoundary>
          </Provider>
        </UnderConstructionDialogProvider>
      </UserContextProvider>
    </QueryClientProvider>
  )
}

export default App
