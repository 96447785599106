/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StreamStatus = {
    Offline: 'offline',
    Live: 'live'
} as const;
export type StreamStatus = typeof StreamStatus[keyof typeof StreamStatus];


export function StreamStatusFromJSON(json: any): StreamStatus {
    return StreamStatusFromJSONTyped(json, false);
}

export function StreamStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreamStatus {
    return json as StreamStatus;
}

export function StreamStatusToJSON(value?: StreamStatus | null): any {
    return value as any;
}

