import { create, props } from '@stylexjs/stylex'
import { color, elementSizes } from '../../styles/token.stylex'
import { Logo } from '../../components/logos/Logo'
import { NavLink } from 'react-router-dom'
import { LoggedInState } from './LoggedInState'
import { LoggedOutState } from './LoggedOutState'
import { NavContext } from './types'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  navbar: {
    top: 0,
    position: 'sticky',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingInline: {
      default: '1rem',
      [largeScreen]: '2.5rem',
    },
    backgroundColor: color.desktopNavbarBackground,
    height: elementSizes.headerHeight,
    zIndex: 1000000,
  },
})

type NavbarProps = {
  displayName?: string
} & NavContext

export const Navbar: React.FC<NavbarProps> = ({
  displayName,
  avatarImageSrc,
  messageCount,
  unreadNotifications,
  onNotificationClick,
  onMailClick,
}) => (
  <nav {...props(styles.navbar)}>
    <NavLink to="/">
      <Logo />
    </NavLink>

    {!!displayName && (
      <LoggedInState
        avatarImageSrc={avatarImageSrc}
        messageCount={messageCount}
        unreadNotifications={unreadNotifications}
        onMailClick={onMailClick}
        onNotificationClick={onNotificationClick}
        displayName={displayName}
      />
    )}
    {!displayName && <LoggedOutState />}
  </nav>
)
