import type { SVGProps } from 'react'

export const Edit: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1065_2758)">
      <path
        d="M4.5 20.0001H8.5L19 9.50006C19.5304 8.96963 19.8284 8.2502 19.8284 7.50006C19.8284 6.74991 19.5304 6.03049 19 5.50006C18.4696 4.96963 17.7501 4.67163 17 4.67163C16.2499 4.67163 15.5304 4.96963 15 5.50006L4.5 16.0001V20.0001Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 6.5L18 10.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1065_2758">
        <rect width="24" height="24" fill={stroke} transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)
