import React from 'react'
import { styled } from '../../stitches'
import EmailVerificationPrompt from '../components/EmailVerificationPrompt'
import useLanguage from '../../shared/stores/useLanguage'

const CompleteRegistration: React.FC = () => {
  const { t } = useLanguage()

  return (
    <Container fullscreen={{ '@initial': true, '@onlyPhone': false }}>
      <ContentWrapper fullscreen={{ '@initial': true, '@onlyPhone': false }}>
        <LeftColumn>
          <EmailVerificationPrompt />
        </LeftColumn>
        <RightColumn visible={{ '@initial': true, '@onlyPhone': false }}>
          <RightColumnTitle>
            <HighlightedText>
              {t('accountRelated.highlightedText')}
            </HighlightedText>{' '}
            {t('accountRelated.subText')}
          </RightColumnTitle>
          <RightColumnDescription>
            {t('accountRelated.descriptionText')}
          </RightColumnDescription>
        </RightColumn>
      </ContentWrapper>
    </Container>
  )
}

export default CompleteRegistration

const Container = styled('div', {
  backdropFilter: 'blur(5px)',
  position: 'fixed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 999,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  height: '100%',
  width: '100%',
  maxHeight: '100%',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  variants: {
    fullscreen: {
      false: {
        alignItems: 'flex-start',
      },
    },
  },
})

const ContentWrapper = styled('div', {
  background: 'rgba(30, 30, 30, 1)',
  display: 'flex',
  width: '80%',
  height: '80%',
  maxHeight: '700px',
  maxWidth: '1300px',
  variants: {
    fullscreen: {
      false: {
        width: '100%',
        height: '100%',
        maxHeight: 'unset',
      },
    },
  },
})

const LeftColumn = styled('div', {
  padding: '2rem',
})

const RightColumn = styled('div', {
  background: '#000000',
  padding: '0.5rem',
  flex: '1 0 50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  variants: {
    visible: {
      false: { display: 'none' },
    },
  },
})

const RightColumnTitle = styled('h3', {
  fontSize: '24px',
  fontWeight: '700',
  color: '#FFFFFF',
  textAlign: 'center',
  marginBottom: '0.5rem',
})

const HighlightedText = styled('span', {
  color: '#00FF00',
})

const RightColumnDescription = styled('p', {
  fontSize: '16px',
  color: '#FFFFFF',
  textAlign: 'center',
})
