import { useAuth0 } from '@auth0/auth0-react'
import { PropsWithChildren, useEffect } from 'react'
import { useApi } from '../shared/utils/ApiContext'
import { useQuery, useQueryClient } from 'react-query'
import { UserContext } from './user-context'
import CompleteRegistration from '../OnlyStream/pages/CompleteRegistrationPage'
import { LoadingScreen } from '../components/indicators/LoadingScreen'

const userKey = 'user'

export const UserContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const {
    user,
    isAuthenticated,
    isLoading: auth0Loading,
    error: auth0Error,
  } = useAuth0()
  const { userApi } = useApi()
  const queryClient = useQueryClient()

  const { data, isLoading, error } = useQuery({
    queryKey: [userKey],
    queryFn: async () => {
      if (isAuthenticated) {
        return await userApi.userGet()
      }

      return null
    },
  })

  useEffect(() => {
    queryClient.invalidateQueries(userKey)
  }, [isAuthenticated, queryClient])

  const isUserLoading = auth0Loading || (isAuthenticated && isLoading)
  const isVerificationNeeded = user && !user.email_verified

  return (
    <UserContext.Provider
      value={{
        isLoading: auth0Loading || isLoading,
        isError: !!(auth0Error || error),
        data: data ?? undefined,
      }}
    >
      {isUserLoading && <LoadingScreen />}
      {!isUserLoading && isVerificationNeeded && <CompleteRegistration />}
      {!isUserLoading && !isVerificationNeeded && children}
    </UserContext.Provider>
  )
}
